import SinglePublication from './SinglePublication';
import withGlobalAppLayout from '../../containers/HOC/withGlobalAppLayout';
import { ReactElement } from 'react';

export default function index(props: any): ReactElement
{
    return (
        withGlobalAppLayout(SinglePublication, {
            ...props,
            setDrawerStatus: () => false,
            setUser: () => false,
            appDrawerIsOpen: false,
            pathname: '',
            hideGfLogo: false,
        })
    );
}
